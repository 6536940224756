<template>
  <div class="userlist">
    <div class="content">
      <div class="tit">
        <el-button class="box submit" @click="editAndAddMaterial()">添加用户</el-button>
      </div>
      <div class="table">
        <el-table
          :data="tableData"
          style="width: 100% "
          :header-cell-style="{textAlign:'center'}"
          :cell-style="{textAlign:'center'}">
          <el-table-column
            prop="username"
            label="姓名"
            :show-overflow-tooltip="true">
          </el-table-column>>
          <el-table-column
            prop="name"
            label="用户类型"
            :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.istop"
                  @change="changeStatus($event,scope.row,scope.$index)">
                </el-switch>
              </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button @click="editAndAddMaterial(scope.row)" type="text" size="small" icon="el-icon-edit">编辑</el-button>
              <el-button type="text" size="small" @click="deleuser(scope.row)" icon="el-icon-delete">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" width="680px">
        <el-form :model="form">
            <el-form-item label="姓名" :label-width="formLabelWidth">
                <el-input v-model="form.name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item v-if="ifMaterialEdit == 1" label="密码" :label-width="formLabelWidth">
                <el-input v-model="form.password" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="用户类型" :label-width="formLabelWidth">
                <el-select v-model="form.type" placeholder="请选择用户类型">
                  <el-option v-for="item in typelist" :key="item.id"  :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="makeSure()">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {getAdminUserAll,delAdminUser,getAdminUserOne,addAdminUser,getAdminRoleAllList,updateAdminUserState} from '@/utils/api'
import { Message } from 'element-ui';
export default {
  inject: ["reload"],
    data(){
        return{
            tableData: [],
            typelist:[],
            dialogFormVisible:false,
            form:{
                name:'',
                password:'',
                radio:0,
                type:'',
            },
            dialogTitle: '',
			      ifMaterialEdit: 0, // 0表示编辑，1表示新增
            formLabelWidth: '100px'
        }
    },
    methods:{
      // 获取列表
      getuserlist(){
        getAdminUserAll().then(res =>{
          // console.log(res);
          res.data.data.map(item =>{
            if(item.istop == 0){
              item.istop = true
            }else if(item.istop == 1){
              item.istop = false
            }
          })
          this.tableData=res.data.data
        })
      },
      // 获取用户类型
      getusertype(){
        getAdminRoleAllList().then(res =>{
          // console.log('获取用户类型',res);
          this.typelist=res.data.data
        })
      },
      // 编辑用户
      edit(id){
        getAdminUserOne({userId:id}).then(res =>{
          console.log('编辑用户',res);
          this.form.name=res.data.data.username
          this.form.type=res.data.data.rid
        })
      },
      changeStatus(e,row,index){
        // console.log(e,row,index)
        if(e == true){
          var stue = 0
        }else if(e == false){
          var stue = 1
        }
        updateAdminUserState({
          userId:row.id,
          state:stue
        }).then(res =>{
          // console.log(res);
          if(res.data.code == 200){
            Message({ message:'修改状态成功',type:"success" })
            this.reload();
          }
        })
      },
      makeSure() {
        // console.log(this.form.name);
        let params = {
          id:this.idx,
          username: this.form.name,
          password: this.form.password,
          rid: this.form.type,
        }
        // 新增接口对接
        if (this.ifMaterialEdit == 1) {
          // console.log('新增')
          this.save(params)
        } else if (this.ifMaterialEdit == 0) {
          // 编辑接口对接
          // console.log('编辑')
          this.save(params)
        }
        this.dialogFormVisible = false
      },
      save(params){
        addAdminUser(params).then(res =>{
          if(res.data.code == 200){
            Message({ message:'保存成功',type:"success" })
            this.reload();
          }
        })
      },
      editAndAddMaterial(row) {
        if (row) {
          this.ifMaterialEdit = 0
          this.dialogFormVisible = true
          this.dialogTitle = '编辑用户'
          // console.log(row)
          this.edit(row.id)
          // let type = this.typelist
          // let list = type.find((item)=> item.name = row.name)
          // console.log('用户类型',list);
          // let id = list.id
          // console.log(id);
          // console.log(this.typelist);
          // this.form.type=row.name
          this.idx = row.id
        } else {
          this.ifMaterialEdit = 1
          this.dialogFormVisible = true
          this.dialogTitle = '新增用户'
          this.form.name=''
          this.form.type=''
          this.idx = ''
        }
      },
      // 删除用户
      deleuser(row){
        // console.log(row);
        this.$confirm("是否删除对应用户？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
          delAdminUser({userId:row.id}).then(res =>{
            // console.log(res);
            if(res.data.code == 200){
              Message({ message:'删除成功',type:"success" })
              this.reload();
            }
          })
        })
        .catch(() => {
          Message.info("已取消删除!");
        });
      },
    },
    mounted(){
      this.getuserlist()
      this.getusertype()
    }
}
</script>

<style  lang="less" scoped>
.userlist{
  /* width: 1680px; */
    /* height: 940px; */
    // height: 90%;
    min-height: 95%;
    background: #FFFFFF;
    box-shadow: 0px 3px 20px 0px rgba(131,131,131,0.1800);
    border-radius: 4px;
    padding: 24px;
}
.block{
  margin-top: 20px;
}
.el-pagination {
    text-align: center; 
}
::v-deep .el-input__inner {
  width: 450px !important;
}
</style>